<template>
  <v-data-table
    :headers="headers"
    :items="globalPromotions"
    :loading="loading"
    :options.sync="options"
    :server-items-length="getGlobalPromotionsTotalCount"
    :footer-props="{ 'items-per-page-options': [5, 10, 15, 20], 'show-current-page': true, 'show-first-last-page': true }"
  >
    <template v-slot:item.id="{ item }">
      <router-link
        :to="{
          name: 'GlobalPromotionCard',
          params: { promotionId: item.id },
        }"
        class="text-decoration-none"
      >
        {{ item.id }}
      </router-link>
    </template>
    <template v-slot:item.start_at="{ item }">
      {{ item.start_at | getShortDate }}
      <sup>{{ item.start_at | getShortTime }}</sup>
    </template>
    <template v-slot:item.expired_at="{ item }">
      <span :class="{ 'red--text text--lighten-1': isDateExpired(item.expired_at) }">
        {{ item.expired_at | getShortDate }}
        <sup>{{ item.expired_at | getShortTime }}</sup>
      </span>
    </template>
    <template v-slot:item.type="{ item }">{{ getPromType(item.type) }}</template>
    <template v-slot:item.is_available_once="{ item }">{{ item.is_available_once ? 'Разова' : 'Постійна' }} </template>
    <template v-slot:item.is_active="{ item }">
      <CircleIndicator :is-on="item.is_active" />
    </template>
    <template v-slot:item.users="{ item }">
      <div v-for="user in item.users" :key="user.id">
        <router-link
          :to="{
            name: 'UserCard',
            params: { id: currentCompanyId, userId: user.id },
          }"
          class="text-decoration-none"
        >
          {{ user.first_name }} {{ user.last_name }}
        </router-link>
      </div>
    </template>
    <template v-slot:item.value_type="{ item }">{{ item.value_type === 1 ? 'грн.' : '%' }}</template>
    <template v-slot:item.amount="{ item }">
      <div v-if="!!item.conditions.length">{{ getConditionsMinMax(item.conditions) }}</div>
      <div v-else>{{ item.amount }}</div>
    </template>
    <template v-slot:item.devices="{ item }">
      <div v-for="(device, index) in item.devices" :key="index">
        <router-link :to="{ name: 'DeviceCard', params: { deviceId: device.id } }" class="text-decoration-none">
          {{ device.id }}
        </router-link>
      </div>
    </template>
    <template v-slot:item.service_points="{ item }">
      <div v-for="point in item.service_points" :key="point.id">
        <router-link
          :to="{
            name: 'ServicePointCard',
            params: { id: currentCompanyId, servicePointId: point.id },
          }"
          class="text-decoration-none"
        >
          {{ point.name }}
        </router-link>
      </div>
    </template>
    <template v-slot:item.actions="{ item }">
      <TableActions @edit="$emit('edit', item)" @delete="deletePromotion(item)" @view="navigateToPromotionCard(item)" />
    </template>
  </v-data-table>
</template>

<script>
import TableActions from '@/components/common/forms/TableActions'
import CircleIndicator from '@/components/common/CircleIndicator'
import { mapGetters, mapState } from 'vuex'
import moment from 'moment/moment'
import convertDate from '@/mixins/convertDate'
import sortUtils from '@/mixins/sortUtils'
import conditionsMinMax from '@/mixins/conditionsMinMax'

export default {
  name: 'PromotionsListLocal',

  components: { CircleIndicator, TableActions },

  mixins: [convertDate, sortUtils, conditionsMinMax],

  emits: ['edit', 'delete', 'paginate'],

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      search: '',
      headers: [
        { text: 'ID', align: 'left', sortable: true, value: 'id' },
        {
          text: 'Термін дії з',
          align: 'left',
          sortable: true,
          value: 'start_at',
        },
        {
          text: 'Термін дії до',
          align: 'left',
          sortable: true,
          value: 'expired_at',
        },
        {
          text: 'Вид акції',
          align: 'left',
          sortable: false,
          value: 'is_available_once',
        },
        { text: 'Тип акції', align: 'left', sortable: true, value: 'type' },
        { text: 'Назва акції', align: 'left', sortable: true, value: 'title' },
        {
          text: 'Включено',
          align: 'center',
          sortable: false,
          value: 'is_active',
        },
        { text: 'Клієнти', align: 'left', sortable: false, value: 'users' },
        {
          text: 'Вид бонуса',
          align: 'left',
          sortable: false,
          value: 'value_type',
        },
        {
          text: 'Розмір бонуса',
          align: 'right',
          sortable: true,
          value: 'amount',
        },
        {
          text: 'Торгівельна точка',
          align: 'left',
          sortable: false,
          value: 'service_points',
        },
        { text: 'Автомат', align: 'left', sortable: false, value: 'devices' },
        {
          text: '',
          value: 'actions',
          sortable: false,
          width: '125px',
          align: 'center',
        },
      ],
      options: {},
    }
  },

  watch: {
    options: {
      async handler() {
        this.$emit('paginate', {
          page: this.options.page,
          limit: this.options.itemsPerPage,
          sort: this.sortObject,
        })
      },
      deep: true,
    },
    inProgress(val) {
      this.loading = val
    },
  },

  computed: {
    ...mapState('promotions', ['globalPromotions', 'globalPromotionsMeta']),
    ...mapState('dictionaries', ['companyPromotionsDictionary']),
    ...mapGetters('promotions', ['getGlobalPromotionsTotalCount']),

    currentCompanyId() {
      return this.$route.params.id
    },
  },

  methods: {
    getPromType(type) {
      const prom = this.companyPromotionsDictionary.find((item) => item.value === type)
      return prom?.name || null
    },

    isDateExpired(date) {
      return moment(date) < new Date()
    },

    deletePromotion(item) {
      this.$emit('delete', item.id)
    },

    navigateToPromotionCard(item) {
      this.$router.push({
        name: 'GlobalPromotionCard',
        params: {
          promotionId: item.id.toString(),
        },
      })
    },
  },
}
</script>
